import { css, Theme, useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Types } from '@scorenco/core';

import {
  Image,
  Markdown,
  SlideTabPanel,
  TabButton,
  TabList,
  TabPanel,
  TabsProvider,
  Wrapper,
} from '../../primitives';

const cs = (theme: Theme) => css`
  --dot-size: 1.25rem;
  --box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.07);
  --border-radius: 0.5rem;

  .tabs {
    display: flex;
    flex-direction: row-reverse;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: var(--box-shadow);
    min-height: 20rem;
    background: ${theme.vars.palette.background.paper};
  }

  .tabs-title {
    display: none;
    width: 100%;
    text-align: center;
  }

  .MuiTabs-root {
    border-left: 1px solid ${theme.vars.palette.divider};
  }

  .MuiTabs-flexContainer {
    height: 100%;
    justify-content: space-evenly;
    width: 20rem;
  }

  .MuiTabs-indicator {
    left: 0;
    right: unset;
    background-color: ${theme.vars.palette.secondary.main};
    width: 3px;
  }

  .tabs-panel {
    flex: 1;
    background: ${theme.vars.palette.background.paper};
  }

  .tabs-button {
    color: ${theme.vars.palette.primary.main};
  }

  ${theme.breakpoints.down('md')} {
    .tabs {
      position: relative;
      margin-bottom: calc(var(--dot-size) + 3rem);
      box-shadow: none;
      border-radius: 0;
      overflow: visible;
      flex-direction: column-reverse;
      min-height: auto;
      background: transparent;
    }

    .tabs-title {
      display: block;
      text-align: center;
      font-weight: 700;
      font-family: ${theme.typography.fontFamilyAlt};
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }

    .tabs-panel {
      border-radius: var(--border-radius);
      overflow: hidden;
      box-shadow: var(--box-shadow);
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-root {
      position: absolute;
      border: 0;
      background: transparent;
      bottom: calc(-1 * (var(--dot-size) + 3rem));
      left: 0;
      right: 0;
    }

    .MuiTabs-flexContainer {
      width: 100%;
      height: auto;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .tabs-button {
      border: 0;
      color: ${theme.vars.palette.primary.light};
      background-color: ${theme.vars.palette.primary.light};
      border-radius: 9999px;
      flex: 0 0 var(--dot-size);
      height: var(--dot-size);
      min-height: var(--dot-size);
      margin: 0.25rem 1rem;
      transition: transfom 0.2s ease-in-out;
      box-shadow: var(--box-shadow);
      min-width: 0;
      padding: 0;

      &:hover {
        transform: scale(1.1);
      }

      &[aria-selected='true'] {
        transform: scale(1.1);

        color: ${theme.vars.palette.primary.main};
        background-color: ${theme.vars.palette.primary.main};
      }

      span {
        display: none;
      }
    }
  }
`;

type VerticalListSliceProps = {
  slice: { children: Types.ComponentSlicesVerticalTab[] };
};

export const VerticalListSlice = ({ slice }: VerticalListSliceProps) => {
  const theme = useTheme();
  const desktopScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Wrapper css={cs} marginY={8}>
      <Box maxWidth="md" marginX="auto">
        <TabsProvider value="0">
          <Box className="tabs">
            <TabList orientation="vertical">
              {slice.children.map(({ title }, idx) => (
                <TabButton
                  key={idx}
                  className="tabs-button"
                  value={`${idx}`}
                  label={title}
                />
              ))}
            </TabList>

            {slice.children.map(({ title, content, image }, idx) => (
              <SlideTabPanel
                nbTabs={slice.children?.length}
                key={idx}
                index={idx}
                disabled={desktopScreen}
              >
                <TabPanel className="tabs-panel" value={`${idx}`} key={idx}>
                  <Typography variant="h3" className="tabs-title">
                    {title}
                  </Typography>
                  {image && (
                    <Image
                      src={image.data?.attributes?.url}
                      width={240}
                      height={240}
                      css={(theme) => css`
                        display: block;
                        margin: 0 auto ${theme.spacing(2)};
                        object-fit: contain;
                      `}
                    />
                  )}
                  <Markdown
                    value={content}
                    css={css`
                      text-align: center;
                    `}
                  />
                </TabPanel>
              </SlideTabPanel>
            ))}
          </Box>
        </TabsProvider>
      </Box>
    </Wrapper>
  );
};
